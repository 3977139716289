.footer {

  &__container {
    @include flex;
    @include image-set("../img/form");

    background-color: var(--grey-silks);
    border-radius: 15px;
    padding: 51px 52px;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-position: 796px 23px;
    box-shadow: 0 14px 40px var(--dull-grey);

    @include xs-mobile {
      flex-wrap: wrap;
      flex-direction: column-reverse;
      padding: 25px;
    }
  }

  &__left {
    @include flex;

    flex-direction: column;
    width: 33%;
    margin-right: 30px;

    @include xs-mobile {
      width: 100%;
      margin-right: 0;
    }
  }

  &__copy {
    color: var(--light-color);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;

    @include mobile {
      font-size: 13px;
    }

    @include xs-mobile {
      font-size: 11px;
      text-align: center;
      line-height: 20px;
    }
  }

  &__text {
    color: var(--light-color);
    font-weight: 400;
    font-size: 11px;
    line-height: 30px;
    text-align: justify;
    transition: color .3s ease-in-out, outline .3s ease-in-out;

    @include xs-mobile {
      font-size: 11px;
      text-align: center;
      line-height: 20px;
    }
  }

  &__text:focus {
    color: var(--dull-grey);
    outline-offset: 5px;
    outline: 1px solid var(--dull-grey);
  }

  &__text:hover {
    color: var(--lavender);
  }

  &__text:active {
    color: var(--dull-grey);
  }

  &__center {
    @include flex;

    flex-direction: column;
    width: 36%;
    margin-right: 30px;

    @include small-tablet {
      width: 55%;
    }

    @include xs-mobile {
      width: 100%;
    }
  }

  &__address {
    color: var(--white);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    margin: 0;

    @include xs-mobile {
      text-align: center;
    }
  }

  &__phone {
    color: var(--white);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;
    transition: color .3s ease-in-out;

    @include xs-mobile {
      text-align: center;
    }
  }

  &__phone:focus {
    color: var(--dull-grey);
  }

  &__phone:hover {
    color: var(--dull-grey);
  }

  &__phone:active {
    color: var(--dull-grey);
  }

  &__mail {
    color: var(--white);
    font-weight: 400;
    font-size: 15px;
    line-height: 30px;
    text-align: justify;
    transition: color .3s ease-in-out;

    @include xs-mobile {
      text-align: center;
    }
  }

  &__mail:focus {
    color: var(--dull-grey);
  }

  &__mail:hover {
    color: var(--dull-grey);
  }

  &__mail:active {
    color: var(--dull-grey);
  }

  &__right {
    width: 33%;
    align-items: flex-end;

    @include small-tablet {
      width: 0%;
    }

    @include xs-mobile{
      width: 100%;
    }
  }

  &__social {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include xs-mobile{
      flex-direction: row;
      justify-content: space-evenly;
    }
  }

  &__social-link svg {
    fill: var(--dull-grey);
  }

  &__social-item:not(:last-child) {
    margin-bottom: 10px;
  }
}
