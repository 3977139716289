:root {
  --font-family: "Montserrat", sans-serif;
  --content-width: 1160px;
  --content-fluid-width: 1790px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));
  --light-color: #fff;
  --dull-grey: #736068;
  --white-antique: #f7e8d4;
  --signalling-gray: #91a897;
  --white: #fff;
  --violet: #7a6069;
  --grey-silks: #c7b7a8;
  --lavender: #e6f0f2;
}

/* stylelint-disable */ /* stylelint-disable */ /* stylelint-disable */
.custom-checkbox__field:checked + .custom-checkbox__content::after {
  opacity: 1;
}

.custom-checkbox__field:focus + .custom-checkbox__content::before {
  outline: 2px solid #f00;
  outline-offset: 2px;
}

.custom-checkbox__field:disabled + .custom-checkbox__content {
  opacity: 0.4;
  pointer-events: none;
}

/* stylelint-disable */ /* stylelint-disable */
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Light.woff2") format("woff2");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Regular.woff2") format("woff2");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Medium.woff2") format("woff2");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  src: url("../fonts/../fonts/Montserrat-Bold.woff2") format("woff2");
  font-weight: 600;
  font-display: swap;
  font-style: normal;
}
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.page {
  height: 100%;
  font-family: var(--font-family, sans-serif);
  -webkit-text-size-adjust: 100%;
}

.page__body {
  margin: 0;
  min-width: 320px;
  min-height: 100%;
  font-size: 16px;
  background-color: var(--signalling-gray);
}

img {
  height: auto;
  max-width: 100%;
  object-fit: cover;
}

a {
  text-decoration: none;
}

.site-container {
  overflow: hidden;
}

.is-hidden {
  display: none !important; /* stylelint-disable-line declaration-no-important */
}

.btn-reset {
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
}

.list-reset {
  list-style: none;
  margin: 0;
  padding: 0;
}

.input-reset {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  border-radius: 0;
  background-color: #fff;
}
.input-reset::-webkit-search-decoration, .input-reset::-webkit-search-cancel-button, .input-reset::-webkit-search-results-button, .input-reset::-webkit-search-results-decoration {
  display: none;
}

.visually-hidden {
  position: absolute;
  overflow: hidden;
  margin: -1px;
  border: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  clip: rect(0 0 0 0);
}

.container {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-width);
}

.container-fluid {
  margin: 0 auto;
  padding: 0 var(--container-offset);
  max-width: var(--container-fluid-width);
}

.js-focus-visible :focus:not(.focus-visible) {
  outline: none;
}

.centered {
  text-align: center;
}

.dis-scroll {
  position: fixed;
  left: 0;
  top: 0;
  overflow: hidden;
  width: 100%;
  height: 100vh;
  overscroll-behavior: none;
}

.page--ios .dis-scroll {
  position: relative;
}

.header {
  position: relative;
  left: 0;
  top: 0;
  padding: 40px 0;
  background-color: var(--white-antique);
  box-shadow: 0 12px 40px var(--violet);
  border-radius: 0 0 15px 15px;
  margin-bottom: 50px;
}
.header__title {
  color: var(--dull-grey);
  text-align: center;
  margin: 0;
}
@media (max-width: 768px) {
  .header__title {
    font-size: 21px;
  }
}
@media (max-width: 576px) {
  .header__title {
    font-size: 15px;
  }
}
@media (max-width: 450px) {
  .header__title {
    font-size: 18px;
  }
}
.header__container {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 450px) {
  .header__container {
    justify-content: center;
    flex-wrap: wrap;
  }
}
.header__top {
  display: flex;
}
@media (max-width: 450px) {
  .header__top {
    display: flex;
  }
}
.header__contacts {
  display: flex;
  flex-direction: column;
}
.header__nav {
  display: flex;
  justify-content: space-between;
  background: var(--grey-silks);
  border-radius: 15px;
  padding: 25px 20px;
  align-items: center;
}
.header__btn-price {
  color: var(--dull-grey);
  font-weight: 500;
}
.header__social-item {
  display: flex;
  justify-content: space-around;
}
.header__social-link:not(:last-child) {
  margin-right: 15px;
}

.social svg {
  fill: var(--dull-grey);
  transition: fill 0.3s ease-in-out, outline 0.3s ease-in-out;
}

.social__wat:focus svg {
  outline-offset: 5px;
  outline: 1px solid #47c757;
  fill: #47c757;
}

.social__wat:hover svg {
  fill: #47c757;
}

.social__wat:active svg {
  fill: #47c757;
}

.social__vib:focus svg {
  outline-offset: 5px;
  outline: 1px solid #574e92;
  fill: #574e92;
}

.social__vib:hover svg {
  fill: #574e92;
}

.social__vib:active svg {
  fill: #574e92;
}

.social__tel:focus svg {
  outline-offset: 5px;
  outline: 1px solid #26a5e4;
  fill: #26a5e4;
}

.social__tel:hover svg {
  fill: #26a5e4;
}

.social__tel:active svg {
  fill: #26a5e4;
}

@media (max-width: 450px) {
  .nav {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    background-color: var(--dull-grey);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }
}
.nav__list {
  display: flex;
  align-items: center;
  text-align: center;
}
@media (max-width: 450px) {
  .nav__list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
  }
}
.nav__item {
  margin-right: 50px;
}
@media (max-width: 768px) {
  .nav__item {
    margin-right: auto;
  }
}
@media (max-width: 450px) {
  .nav__item {
    margin-right: 0;
    margin-bottom: 30px;
  }
}
.nav__link {
  color: var(--light-color);
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .nav__link {
    font-size: 15px;
  }
}
@media (max-width: 576px) {
  .nav__link {
    font-size: 9px;
  }
}
@media (max-width: 450px) {
  .nav__link {
    font-weight: 600;
    font-size: 16px;
  }
}
.nav__link:focus {
  color: var(--dull-grey);
}
.nav__link:hover {
  color: var(--lavender);
}
.nav__link:active {
  color: var(--dull-grey);
}

.menu--active {
  opacity: 1;
  visibility: visible;
}

.burger {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: none;
}
@media (max-width: 450px) {
  .burger {
    display: block;
  }
}
.burger__line {
  position: absolute;
  top: 50%;
  left: 50%;
  border-radius: 100px;
  height: 2px;
  width: 26px;
  background-color: var(--light-color);
  transform: translateX(-50%) translateY(-50%);
}
.burger__line:first-child {
  margin-top: -7px;
}
.burger__line:last-child {
  margin-top: 7px;
}

.burger-close {
  position: absolute;
  display: none;
  top: 30px;
  right: var(--container-offset);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;
}
@media (max-width: 450px) {
  .burger-close {
    display: block;
  }
}

.phone {
  color: var(--dull-grey);
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
  transition: color 0.3s ease-in-out, outline 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .phone {
    font-size: 17px;
  }
}
@media (max-width: 576px) {
  .phone {
    font-size: 15px;
  }
}
.phone:focus {
  color: var(--grey-silks);
  outline-offset: 5px;
  outline: 1px solid var(--grey-silks);
}
.phone:hover {
  color: var(--grey-silks);
}
.phone:active {
  color: var(--white);
}

.mail {
  color: var(--dull-grey);
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
  transition: color 0.3s ease-in-out, outline 0.3s ease-in-out;
}
@media (max-width: 768px) {
  .mail {
    font-size: 17px;
  }
}
@media (max-width: 576px) {
  .mail {
    font-size: 15px;
  }
}
.mail:focus {
  color: var(--grey-silks);
  outline-offset: 5px;
  outline: 1px solid var(--grey-silks);
}
.mail:hover {
  color: var(--grey-silks);
}
.mail:active {
  color: var(--white);
}

.btn {
  color: var(--dull-grey);
  font-weight: 500;
  font-size: 15px;
  padding: 10px;
  background: var(--white-antique);
  border-radius: 10px;
  border: 1px solid var(--light-color);
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}
@media (max-width: 576px) {
  .btn {
    font-size: 10px;
  }
}
@media (max-width: 450px) {
  .btn {
    font-size: 15px;
  }
}
.btn:focus {
  background-color: var(--grey-silks);
  color: var(--light-color);
}
.btn:hover {
  background-color: var(--grey-silks);
  color: var(--light-color);
}
.btn:active {
  background-color: var(--signalling-gray);
  color: var(--light-color);
}

.services {
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .services {
    margin-bottom: 0;
  }
}
.services__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--light-color);
  margin: 0;
  margin-bottom: 35px;
}
@media (max-width: 450px) {
  .services__title {
    font-size: 35px;
  }
}
.services__list {
  display: flex;
  justify-content: center;
}
@media (max-width: 450px) {
  .services__list {
    flex-wrap: wrap;
  }
}
.services__item {
  position: relative;
  flex-direction: column;
  text-align: center;
  padding: 60px 30px;
  width: 270px;
  border-radius: 5px;
}
.services__item:not(:nth-child(4n)) {
  margin-right: 30px;
}
.services__number {
  display: block;
  position: absolute;
  left: 114px;
  top: -4px;
  color: var(--lavender);
  font-size: 150px;
  z-index: -1;
  opacity: 0.4;
}
@media (max-width: 768px) {
  .services__number {
    font-size: 75px;
  }
}
@media (max-width: 450px) {
  .services__number {
    font-size: 95px;
  }
}
.services__item-title {
  margin: 0;
  position: absolute;
  color: var(--white);
  width: 167px;
  text-align: center;
  right: 40px;
}
@media (max-width: 768px) {
  .services__item-title {
    width: 120px;
    font-size: 15px;
    right: 15px;
    top: 25px;
  }
}
@media (max-width: 450px) {
  .services__item-title {
    width: 66%;
    top: 29px;
    right: 38px;
    font-size: 23px;
    font-weight: 400;
  }
}

.about {
  margin-bottom: 50px;
}
.about__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--white);
  margin: 0;
  margin-bottom: 35px;
}
.about__container {
  display: flex;
  justify-content: space-between;
}
.about__left {
  width: 650px;
  margin-right: 50px;
}
@media (max-width: 768px) {
  .about__left {
    width: 100%;
    margin-right: 0;
  }
}
.about__text {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  justify-content: space-between;
  line-height: 130%;
  text-align: justify;
  margin: 0;
}
@media (max-width: 450px) {
  .about__text {
    font-size: 15px;
  }
}
.about__image {
  border-radius: 15px;
}
@media (max-width: 768px) {
  .about__right {
    display: none;
  }
}

.form {
  margin-bottom: 50px;
}
.form__container {
  background-color: rgba(122, 96, 105, 0.431372549);
  border-radius: 15px;
  padding: 35px;
}
@media (max-width: 450px) {
  .form__container {
    padding: 15px;
  }
}
.form__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--white);
  margin: 0;
  margin-bottom: 20px;
}
@media (max-width: 450px) {
  .form__title {
    font-size: 35px;
  }
}
.form .just-validate-error-label {
  margin-bottom: 3px;
  color: var(--dull-grey) !important;
}
.form__label {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  margin-right: 10px;
}
@media (max-width: 768px) {
  .form__label {
    width: 48%;
    margin-bottom: 10px;
  }
}
@media (max-width: 576px) {
  .form__label {
    width: 100%;
  }
}
.form__input {
  border: 1px solid var(--violet);
  background: var(--white-antique);
  border-radius: 10px;
  font-size: 15px;
  padding: 5px;
  width: 100%;
  height: 60%;
  resize: none;
  line-height: 38px;
}
@media (max-width: 1370px) {
  .form__input {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .form__input {
    height: 100%;
    font-size: 15px;
  }
}
.form__btn {
  height: 60%;
}
@media (max-width: 1370px) {
  .form__btn {
    font-size: 12px;
  }
}
@media (max-width: 576px) {
  .form__btn {
    height: 100%;
  }
}
.form__order {
  display: flex;
  padding: 30px 10px;
  border-radius: 15px;
  background: var(--white-antique);
}
@media (max-width: 768px) {
  .form__order {
    flex-wrap: wrap;
    padding: 15px 10px;
  }
}
.form__text-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.form__text-file + label {
  color: #878983;
  background-color: #f7e8d4;
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--violet);
  border-radius: 10px;
  margin-right: 10px;
  height: 60%;
  padding: 5px;
  width: 100%;
  line-height: 38px;
  transition: 0.3s ease-in-out;
}
@media (max-width: 1370px) {
  .form__text-file + label {
    height: 60%;
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .form__text-file + label {
    height: 100%;
    font-size: 15px;
  }
}
.form__text-file:focus + label, .form__text-file:hover + label {
  background-color: #878983;
  color: #fff;
}

.footer__container {
  display: flex;
  background-image: url("../img/form.png");
  /* stylelint-disable-next-line declaration-block-no-duplicate-properties */
  background-image: image-set("../img/form.avif" type("image/avif"), "../img/form.webp" type("image/webp"), "../img/form.jpg" type("image/jpg"));
  background-color: var(--grey-silks);
  border-radius: 15px;
  padding: 51px 52px;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position: 796px 23px;
  box-shadow: 0 14px 40px var(--dull-grey);
}
@media (max-width: 450px) {
  .footer__container {
    flex-wrap: wrap;
    flex-direction: column-reverse;
    padding: 25px;
  }
}
.footer__left {
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-right: 30px;
}
@media (max-width: 450px) {
  .footer__left {
    width: 100%;
    margin-right: 0;
  }
}
.footer__copy {
  color: var(--light-color);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: justify;
}
@media (max-width: 576px) {
  .footer__copy {
    font-size: 13px;
  }
}
@media (max-width: 450px) {
  .footer__copy {
    font-size: 11px;
    text-align: center;
    line-height: 20px;
  }
}
.footer__text {
  color: var(--light-color);
  font-weight: 400;
  font-size: 11px;
  line-height: 30px;
  text-align: justify;
  transition: color 0.3s ease-in-out, outline 0.3s ease-in-out;
}
@media (max-width: 450px) {
  .footer__text {
    font-size: 11px;
    text-align: center;
    line-height: 20px;
  }
}
.footer__text:focus {
  color: var(--dull-grey);
  outline-offset: 5px;
  outline: 1px solid var(--dull-grey);
}
.footer__text:hover {
  color: var(--lavender);
}
.footer__text:active {
  color: var(--dull-grey);
}
.footer__center {
  display: flex;
  flex-direction: column;
  width: 36%;
  margin-right: 30px;
}
@media (max-width: 768px) {
  .footer__center {
    width: 55%;
  }
}
@media (max-width: 450px) {
  .footer__center {
    width: 100%;
  }
}
.footer__address {
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  margin: 0;
}
@media (max-width: 450px) {
  .footer__address {
    text-align: center;
  }
}
.footer__phone {
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: justify;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 450px) {
  .footer__phone {
    text-align: center;
  }
}
.footer__phone:focus {
  color: var(--dull-grey);
}
.footer__phone:hover {
  color: var(--dull-grey);
}
.footer__phone:active {
  color: var(--dull-grey);
}
.footer__mail {
  color: var(--white);
  font-weight: 400;
  font-size: 15px;
  line-height: 30px;
  text-align: justify;
  transition: color 0.3s ease-in-out;
}
@media (max-width: 450px) {
  .footer__mail {
    text-align: center;
  }
}
.footer__mail:focus {
  color: var(--dull-grey);
}
.footer__mail:hover {
  color: var(--dull-grey);
}
.footer__mail:active {
  color: var(--dull-grey);
}
.footer__right {
  width: 33%;
  align-items: flex-end;
}
@media (max-width: 768px) {
  .footer__right {
    width: 0%;
  }
}
@media (max-width: 450px) {
  .footer__right {
    width: 100%;
  }
}
.footer__social {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
@media (max-width: 450px) {
  .footer__social {
    flex-direction: row;
    justify-content: space-evenly;
  }
}
.footer__social-link svg {
  fill: var(--dull-grey);
}
.footer__social-item:not(:last-child) {
  margin-bottom: 10px;
}

.technology {
  margin-bottom: 50px;
}
.technology__h2 {
  text-align: center;
  font-weight: 600;
  font-size: 34px;
  color: var(--white);
  margin: 0;
  margin-bottom: 25px;
}
.technology__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--white);
  margin: 0;
  margin-bottom: 35px;
}
@media (max-width: 450px) {
  .technology__title {
    font-size: 35px;
    line-height: 100%;
  }
}
.technology__text {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  justify-content: space-between;
  line-height: 180%;
  text-align: justify;
  margin: 0;
}
@media (max-width: 450px) {
  .technology__text {
    font-size: 15px;
  }
}
.technology__item {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  line-height: 180%;
}

.slider__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--white);
  margin: 0;
  margin-bottom: 35px;
}
@media (max-width: 450px) {
  .slider__title {
    font-size: 35px;
    line-height: 100%;
  }
}
.slider__navigation {
  display: flex;
  justify-content: center;
}
.slider__model {
  padding-left: max(15px, (100% - var(--content-fluid-width)) / 2);
}
.slider__image {
  max-height: 300px;
  border-radius: 10px;
}
.slider__slider-btn {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}
.slider__slider-btn--prev {
  background-image: url("../img/prev.svg");
}
.slider__slider-btn--next {
  background-image: url("../img/next.svg");
}
.slider__slider-btn:not(:last-child) {
  margin-right: 10px;
}

.swiper-slide {
  width: 300px;
  border-radius: 15px;
}

.modal__container {
  max-width: 300px;
  text-align: center;
  background: var(--signalling-gray);
}
.modal__text-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.modal__text-file + label {
  color: var(--light-color);
  background-color: var(--signalling-gray);
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--light-color);
  border-radius: 5px;
  padding: 5px;
  width: 100%;
  line-height: 30px;
  margin-bottom: 15px;
  transition: 0.3s ease-in-out;
}
.modal__text-file:focus + label, .modal__text-file:hover + label {
  background-color: #878983;
  color: #fff;
}

.graph-modal__close {
  position: absolute;
  top: 0;
  right: var(--container-offset);
  width: 20px;
  height: 40px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;
}

.form__label-modal {
  display: flex;
  flex-direction: column-reverse;
}
.form__modal-title {
  font-weight: 900;
  font-size: 25px;
  line-height: 20px;
  color: var(--light-color);
  margin-bottom: 20px;
  margin-top: 20px;
}
.form__input-modal {
  border: 1px solid var(--light-color);
  background: var(--signalling-gray);
  border-radius: 5px;
  font-size: 15px;
  line-height: 30px;
  margin-bottom: 15px;
  padding: 5px;
  width: 100%;
  resize: none;
  color: var(--light-color);
}
.form__input-modal::placeholder {
  color: var(--light-color);
}
.form__info {
  color: var(--light-color);
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 15px;
}

.just-validate-error-label {
  margin-bottom: 3px;
  color: var(--light-color) !important;
}

.privacy__title {
  text-align: center;
  font-weight: 300;
  font-size: 40px;
  line-height: 130%;
  color: var(--white);
  margin: 0;
  margin-bottom: 35px;
}
.privacy__list {
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
  justify-content: space-between;
  line-height: 130%;
  text-align: justify;
}
.privacy__text {
  color: var(--white);
  font-weight: 400;
  font-size: 18px;
  justify-content: space-between;
  line-height: 130%;
  text-align: justify;
}