.burger {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  display: none;

  @include xs-mobile {
    display: block;
  }

  &__line {
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 100px;
    height: 2px;
    width: 26px;
    background-color: var(--light-color);
    transform: translateX(-50%) translateY(-50%);

    &:first-child {
      margin-top: -7px;
    }

    &:last-child {
      margin-top: 7px;
    }
  }
}

.burger-close {
  position: absolute;
  display: none;
  top: 30px;
  right: var(--container-offset);
  border: 1px solid rgba(255, 255, 255, 0.08);
  border-radius: 10px;
  width: 40px;
  height: 40px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;

  @include xs-mobile {
    display: block;
  }
}
