.btn {
  color: var(--dull-grey);
  font-weight: 500;
  font-size: 15px;
  padding: 10px;
  background: var(--white-antique);
  border-radius: 10px;
  border: 1px solid var(--light-color);
  transition: background-color .3s ease-in-out, color .3s ease-in-out;

  @include mobile{
      font-size: 10px;
    }

    @include xs-mobile {
      font-size: 15px;
    }

  &:focus {
    background-color: var(--grey-silks);
    color: var(--light-color);
  }

  &:hover {
    background-color: var(--grey-silks);
    color: var(--light-color);
  }

  &:active {
    background-color: var(--signalling-gray);
    color: var(--light-color);
  }
}
