.privacy {
  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--white);
    margin: 0;
    margin-bottom: 35px;
  }

  &__list {
    color: var(--white);
    font-weight: 600;
    font-size: 18px;
    justify-content: space-between;
    line-height: 130%;
    text-align: justify;
  }

  &__text {
    color: var(--white);
    font-weight: 400;
    font-size: 18px;
    justify-content: space-between;
    line-height: 130%;
    text-align: justify;
  }

}
