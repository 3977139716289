.form {
  margin-bottom: 50px;

  &__container {
    background-color: #7a60696e;
    border-radius: 15px;
    padding: 35px;

    @include xs-mobile {
      padding: 15px;
    }
  }

  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--white);
    margin: 0;
    margin-bottom: 20px;

    @include xs-mobile {
      font-size: 35px;
    }
  }

  .just-validate-error-label {
    margin-bottom: 3px;
    color: var(--dull-grey) !important;
  }

  &__label {
    display: flex;
    flex-direction: column-reverse;
    width: 100%;
    margin-right: 10px;

    @include small-tablet {
      width: 48%;
      margin-bottom: 10px;
    }

    @include mobile {
      width: 100%;
    }
  }

  &__input {
    border: 1px solid var(--violet);
    background: var(--white-antique);
    border-radius: 10px;
    font-size: 15px;
    padding: 5px;
    width: 100%;
    height: 60%;
    resize: none;
    line-height: 38px;

    @include hight-desktop {
      font-size: 12px;
    }

    @include small-tablet {
      height: 100%;
      font-size: 15px;
    }
}

&__btn {
  height: 60%;

  @include hight-desktop {
    font-size: 12px;
  }

  @include mobile {
    height: 100%;
  }
}

&__order {
  display: flex;
  padding: 30px 10px;
  border-radius: 15px;
  background: var(--white-antique);

  @include small-tablet {
    flex-wrap: wrap;
    padding: 15px 10px;
  }
}

&__text-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

&__text-file + label {
  color: #878983;
  background-color: #f7e8d4;
  display: inline-block;
  cursor: pointer;
  border: 1px solid var(--violet);
  border-radius: 10px;
  margin-right: 10px;
  height: 60%;
  padding: 5px;
  width: 100%;
  line-height: 38px;
  transition: 0.3s ease-in-out;

  @include hight-desktop {
    height: 60%;
    font-size: 12px;
  }

  @include small-tablet {
    height: 100%;
    font-size: 15px;
  }
}

&__text-file:focus + label,
&__text-file:hover + label {
  background-color: #878983;
  color: #fff;
}
}
