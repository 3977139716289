// если вы хотите использовать sass-переменные - удалите root
// colors
:root {
  // base
  --font-family: "Montserrat", sans-serif;
  --content-width: 1160px;
  --content-fluid-width: 1790px;
  --container-offset: 15px;
  --container-width: calc(var(--content-width) + (var(--container-offset) * 2));
  --container-fluid-width: calc(var(--content-fluid-width) + (var(--container-offset) * 2));

  // colors
  --light-color: #fff;
  --dull-grey: #736068;
  --white-antique: #f7e8d4;
  --signalling-gray: #91a897;
  --white: #fff;
  --violet: #7a6069;
  --grey-silks: #c7b7a8;
  --lavender: #e6f0f2;
}
