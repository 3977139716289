.slider {

  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--white);
    margin: 0;
    margin-bottom: 35px;

    @include xs-mobile {
      font-size: 35px;
      line-height: 100%;
    }
  }

  &__navigation {
    @include flex;

    justify-content: center;
  }

  &__model {
    padding-left: max(15px, calc((100% - var(--content-fluid-width)) / 2));
  }

  &__image {
    max-height: 300px;
    border-radius: 10px;
  }

  &__slider-btn {
    @include bg-cover;

    width: 40px;
    height: 40px;

    &--prev {
      background-image: url("../img/prev.svg");
    }

    &--next {
      background-image: url("../img/next.svg");
    }

    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.swiper-slide {
  width: 300px;
  border-radius: 15px;
}
