.social svg {
  fill: var(--dull-grey);
  transition: fill .3s ease-in-out, outline .3s ease-in-out;
}

.social__wat:focus svg {
  outline-offset: 5px;
  outline: 1px solid #47c757;
  fill: #47c757;
}

.social__wat:hover svg {
  fill: #47c757;
}

.social__wat:active svg {
  fill: #47c757;
}

.social__vib:focus svg {
  outline-offset: 5px;
  outline: 1px solid #574e92;
  fill: #574e92;
}

.social__vib:hover svg {
  fill: #574e92;
}

.social__vib:active svg {
  fill: #574e92;
}

.social__tel:focus svg {
  outline-offset: 5px;
  outline: 1px solid #26a5e4;
  fill: #26a5e4;
}

.social__tel:hover svg {
  fill: #26a5e4;
}

.social__tel:active svg {
  fill: #26a5e4;
}
