.header {
  position: relative;
  left: 0;
  top: 0;
  padding: 40px 0;
  background-color: var(--white-antique);
  box-shadow: 0 12px 40px var(--violet);
  border-radius: 0 0 15px 15px;
  margin-bottom: 50px;

  &__title {
    color: var(--dull-grey);
    text-align: center;
    margin: 0;

    @include small-tablet {
      font-size: 21px;
    }

    @include mobile {
      font-size: 15px;
    }

    @include xs-mobile {
      font-size: 18px;
    }
  }

  &__container {
    @include flex;
    justify-content: space-between;

    @include xs-mobile {
      justify-content: center;
      flex-wrap: wrap;
    }
  }

  &__top {
    display: flex;

    @include xs-mobile {
      display: flex;
    }
  }

  &__contacts {
    @include flex;
    flex-direction: column;
  }

  &__nav {
    @include flex;
    justify-content: space-between;
    background: var(--grey-silks);
    border-radius: 15px;
    padding: 25px 20px;
    align-items: center;
  }

  &__btn-price {
    color: var(--dull-grey);
    font-weight: 500;
  }

  &__social-item {
    @include flex;

    justify-content: space-around;
  }

  &__social-link:not(:last-child) {
    margin-right: 15px;
  }
}
