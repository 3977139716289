.phone {
  color: var(--dull-grey);
  font-weight: 400;
  font-size: 20px;
  line-height: 16px;
  margin-bottom: 10px;
  text-align: center;
  transition: color .3s ease-in-out, outline .3s ease-in-out;

  @include small-tablet {
    font-size: 17px;
  }

  @include mobile{
    font-size: 15px;
  }

  &:focus {
    color: var(--grey-silks);
    outline-offset: 5px;
    outline: 1px solid var(--grey-silks);
  }

  &:hover {
    color: var(--grey-silks);
  }

  &:active {
    color: var(--white);
  }
}
