.modal {

  &__container {
    max-width: 300px;
    text-align: center;
    background: var(--signalling-gray);
  }

  &__text-file {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  &__text-file + label {
    color: var(--light-color);
    background-color: var(--signalling-gray);
    display: inline-block;
    cursor: pointer;
    border: 1px solid var(--light-color);
    border-radius: 5px;
    padding: 5px;
    width: 100%;
    line-height: 30px;
    margin-bottom: 15px;
    transition: 0.3s ease-in-out;
  }

  &__text-file:focus + label,
  &__text-file:hover + label {
  background-color: #878983;
  color: #fff;
}
}

.graph-modal__close {
  position: absolute;
  top: 0;
  right: var(--container-offset);
  width: 20px;
  height: 40px;
  background-image: url("../img/close.svg");
  background-position: center;
  background-size: 14px 12px;
  background-repeat: no-repeat;
}

.form {
  &__label-modal {
    display: flex;
    flex-direction: column-reverse;
  }

  &__modal-title {
    font-weight: 900;
    font-size: 25px;
    line-height: 20px;
    color: var(--light-color);
    margin-bottom: 20px;
    margin-top: 20px;
  }

  &__input-modal {
    border: 1px solid var(--light-color);
    background: var(--signalling-gray);
    border-radius: 5px;
    font-size: 15px;
    line-height: 30px;
    margin-bottom: 15px;
    padding: 5px;
    width: 100%;
    resize: none;
    color: var(--light-color);
  }

  &__input-modal::placeholder {
    color: var(--light-color);
  }

  &__info {
    color: var(--light-color);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 15px;
  }
}

.just-validate-error-label {
  margin-bottom: 3px;
  color: var(--light-color) !important;
}
