.technology {
  margin-bottom: 50px;

  &__h2 {
    text-align: center;
    font-weight: 600;
    font-size: 34px;
    color: var(--white);
    margin: 0;
    margin-bottom: 25px;
  }

  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--white);
    margin: 0;
    margin-bottom: 35px;

    @include xs-mobile{
      font-size: 35px;
      line-height: 100%;
    }
  }

  &__text {
    color: var(--white);
    font-weight: 400;
    font-size: 18px;
    justify-content: space-between;
    line-height: 180%;
    text-align: justify;
    margin: 0;
    // text-indent: 1.5em;

    @include xs-mobile{
      font-size: 15px;
    }
  }

  &__item {
    color: var(--white);
    font-weight: 400;
    font-size: 18px;
    line-height: 180%;
  }
}
