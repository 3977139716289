.nav {
  @include xs-mobile {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 500;
    overflow-y: auto;
    width: 100%;
    height: 100vh;
    background-color: var(--dull-grey);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  &__list {
    @include flex-v-center;
    text-align: center;

    @include xs-mobile {
      @include flex-all-center;

      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }

  &__item {
    margin-right: 50px;

    @include small-tablet {
      margin-right: auto;
    }

    @include xs-mobile {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  &__link {
    color: var(--light-color);
    font-weight: 500;
    font-size: 17px;
    line-height: 16px;
    transition: color .3s ease-in-out;

    @include small-tablet {
      font-size: 15px;
    }

    @include mobile{
      font-size: 9px;
    }

    @include xs-mobile {
      font-weight: 600;
      font-size: 16px;
    }
  }

  &__link:focus {
    color: var(--dull-grey);
  }

  &__link:hover {
    color: var(--lavender);
  }

  &__link:active {
    color: var(--dull-grey);
  }
}

.menu--active {
  opacity: 1;
  visibility: visible;
}
