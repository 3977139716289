.about {
  margin-bottom: 50px;

  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--white);
    margin: 0;
    margin-bottom: 35px;
  }

  &__container {
    @include flex;
    justify-content: space-between;
  }

  &__left {
    width: 650px;
    margin-right: 50px;

    @include small-tablet {
      width: 100%;
      margin-right: 0;
  }
  }

  &__text {
    color: var(--white);
    font-weight: 400;
    font-size: 18px;
    justify-content: space-between;
    line-height: 130%;
    text-align: justify;
    margin: 0;

    @include xs-mobile {
      font-size: 15px;
    }
  }

  &__image {
    border-radius: 15px;
  }

  &__right {
    @include small-tablet {
      display: none;
  }
}
}
