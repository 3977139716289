.services {
  margin-bottom: 50px;

  @include small-tablet {
    margin-bottom: 0;
  }

  &__title {
    text-align: center;
    font-weight: 300;
    font-size: 40px;
    line-height: 130%;
    color: var(--light-color);
    margin: 0;
    margin-bottom: 35px;

    @include xs-mobile {
      font-size: 35px;
    }
  }

  &__list {
    @include flex;
    justify-content: center;

    @include xs-mobile {
      flex-wrap: wrap;
    }
  }

  &__item {
    position: relative;
    flex-direction: column;
    text-align: center;
    padding: 60px 30px;
    width: 270px;
    border-radius: 5px;
  }

  &__item:not(:nth-child(4n)) {
    margin-right: 30px;
  }

  &__number {
    display: block;
    position: absolute;
    left: 114px;
    top: -4px;
    color: var(--lavender);
    font-size: 150px;
    z-index: -1;
    opacity: .4;

    @include small-tablet {
      font-size: 75px;
    }

    @include xs-mobile {
      font-size: 95px;
    }
  }

  &__item-title {
    margin: 0;
    position: absolute;
    color: var(--white);
    width: 167px;
    text-align: center;
    right: 40px;

    @include small-tablet {
      width: 120px;
      font-size: 15px;
      right: 15px;
      top: 25px;
    }

    @include xs-mobile {
      width: 66%;
      top: 29px;
      right: 38px;
      font-size: 23px;
      font-weight: 400;
    }
  }
}
